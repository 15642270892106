import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';

import money_2020_webm from '../../../../../asset/resource/money_2020_webm.webm';
import money_2020 from '../../../../../asset/resource/money_2020.mov';
import avatar_background from '../../../../../asset/resource/avatar_background.png';
import avatar_background_mirrored from '../../../../../asset/resource/avatar_background_mirrored.png';

import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isStart, setIsStart] = useState(false);

  const handleView = (inView) => {
    if (inView) {
      const video = document.getElementById('box-video');
      setTimeout(function () {
        video.play();
      }, 2000);
    }
  };

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);

  return (
    <section
      className={cn('main-title', isSafariBrowser && 'safari')}
      style={{ backgroundImage: `url(${isSafariBrowser ? avatar_background_mirrored : avatar_background})` }}
    >
      <div className="main-title__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <h1>
            Who is Veda,
            <br />{' '}
            <span>
              the <span> AI Pathfinder?</span>
            </span>
          </h1>
        </motion.div>
        <div className="main-title__heading">
          <p className={cn('main-title__description', isStart && 'visible')}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              Meet Veda, the AI Pathfinder – your Generative AI financial guru. Veda engages with you in real-time
              conversations, understanding your financial goals, investment strategies, and risk tolerance.
              <br />
              <br />
              Through tailored guidance, Veda revolutionizes the client experience, offering intelligent financial
              insights and actionable strategies customized for the complex needs of banks, fintech, startups, and
              financial service institutions.
              <br />
              <br />
              Explore financial planning or asset management as you converse naturally with Veda. Share your thoughts,
              ask questions, and discover strategies to maximize investments, all in an environment that mirrors a
              discussion with a human.
              <br />
              <br />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.6 }}
            >
              <ScrollLink smooth spy className="main-title__cta" to="contact-us">
                Book your immersive experience
              </ScrollLink>
            </motion.div>
          </p>

          <div className={cn('main-title__inview')}>
            <InView triggerOnce onChange={handleView} threshold={0}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationComplete={() => setIsStart(true)}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, y: 0, opacity: 1 },
                      }
                    : {
                        visible: {
                          x: 0,
                          y: 0,
                          opacity: 1,
                        },
                        hidden: {
                          x: 300,
                          y: 200,
                          opacity: 0,
                        },
                      }
                }
                transition={{ type: 'anticipate', duration: 3.8, delay: 0.3 }}
              >
                <video id="box-video" className="main__video-content" width="100%" loop muted playsInline>
                  {isSafariBrowser ? (
                    <source src={money_2020} type="video/mp4" />
                  ) : (
                    <source src={money_2020_webm} type="video/webm" />
                  )}
                  Your browser is not supported!
                </video>
              </motion.div>
            </InView>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
