import React from 'react';
import { motion } from 'framer-motion';

//Assets
import generative_ai from '../../../../../asset/resource/generative_ai.png';
import digital_avatar from '../../../../../asset/resource/digital_avatar.png';
import meet_veda_image from '../../../../../asset/resource/meet-veda-image.png';
import behind_the_scene_image from '../../../../../asset/resource/behind-the-scene-image.png';

import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';

export const HowItWorks = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="how-it-works" className="how-it-works">
      <div className="how-it-works__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <span className="sub-title">How It Works</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>
            What’s the technology behind Veda, the <span>AI Pathfinder</span>? 
          </h2>
        </motion.div>
        <div className="how-it-works__blocks">
          <div className="how-it-works__block one">
            <article>
              <h3>Generative AI</h3>
              <p>
                Veda, the AI Pathfinder, is rooted in the power of Generative AI. Crafted by the experts at SoftServe,
                this financial guru is enhanced with a state-of-the-art ChatGPT module. Veda's conversational
                capabilities engage you in natural and dynamic dialogues.
                <br />
                <br />
                Whether exploring financial literacy or complex investment strategies, Veda responds with personalized,
                real-time information, providing relevant insight and explanations.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1.2, delay: 1 }}
            >
              <figure className="how-it-works__image">
                <img width="100%" src={generative_ai} className="generative_ai" alt="generative ai image" />
              </figure>
            </motion.div>
          </div>

          <div className="how-it-works__block two">
            <article>
              <h3>Digital Avatar</h3>
              <p>
                The persona of Veda, the AI Pathfinder, comes to life through advanced digital avatar technology.
                Utilizing the Avatar Cloud Engine (ACE) and NVIDIA Riva, along with the A2F component, SoftServe has
                created a lifelike representation that captures both speech and facial expressions with uncanny
                naturalness.
                <br />
                From model creation in Character's Creator tool to final integration, every detail of Veda's appearance
                is designed to facilitate a seamless and engaging interaction.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: -200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1.2 }}
            >
              <figure className="how-it-works__image">
                <img src={digital_avatar} className="digital_avatar_image" alt="digital avatar image" />
              </figure>
            </motion.div>
          </div>
        </div>
        <div className="how-it-works__video">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? {
                    visible: { y: 0, opacity: 1 },
                  }
                : {
                    visible: { y: 0, opacity: 1, scale: 1 },
                    hidden: { y: 200, opacity: 0, scale: 0.8 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1.2 }}
          >
            <a
              href="https://www.youtube.com/watch?v=U8Gv5Yd-I4w"
              role="button"
              aria-label="video block"
              target="_blank"
              className="how-it-works__video-link"
            >
              <figure className="how-it-works__video-image">
                <div className="how-it-works__video-image-wrapper">
                  <img src={meet_veda_image} alt="video image" />
                </div>
                <figcaption>MEET VEDA</figcaption>
              </figure>
            </a>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? {
                    visible: { y: 0, opacity: 1 },
                  }
                : {
                    visible: { y: 0, opacity: 1, scale: 1 },
                    hidden: { y: 200, opacity: 0, scale: 0.8 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1.2 }}
          >
            <a
              href="https://youtu.be/JcqF3yQ3w8I"
              role="button"
              aria-label="video block"
              target="_blank"
              className="how-it-works__video-link"
            >
              <figure className="how-it-works__video-image">
                <div className="how-it-works__video-image-wrapper">
                  <img src={behind_the_scene_image} alt="video image" />
                </div>
                <figcaption>BEHIND THE SCENES</figcaption>
              </figure>
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
