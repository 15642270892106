import React from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//Assets
import finance_planing from '../../../../../asset/resource/finance-planing.png';
import asset_management from '../../../../../asset/resource/asset-management.png';
import onboarding from '../../../../../asset/resource/onboarding.png';
import customer_service from '../../../../../asset/resource/customer-service.png';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <div className="sub-title-wrapper">
            <span className="sub-title">Use Cases</span>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>
            Veda, the <span>AI Pathfinder</span>, <br />
            use Cases
          </h2>
        </motion.div>
        <div className="use-cases__slider-wrapper">
          <Carousel showArrows infiniteLoop showThumbs={false} showStatus={false}>
            <div className="use-cases__slide">
              <article>
                <h3>Financial Planning </h3>
                <p>
                  Veda, the AI Pathfinder, engages you in a conversation to understand your risk tolerance, spending
                  habits, investment strategies, and financial goals. Real-time information about demographics and
                  locations is collected, allowing for hyper-personalized advice and guidance towards specific targets,
                  such as buying a home.
                  <br />
                  <br />
                  With Veda, you can craft an actionable and realistic financial plan tailored to your unique
                  circumstances. For a young professional saving for their first home, Veda would help set achievable
                  milestones and investment strategies, reflecting the real estate market in their location.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={finance_planing} className="collaboration-image" alt="collaboration image" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>Asset Management</h3>
                <p>
                  Veda, the AI Pathfinder, skilfully analyzes vast amounts of financial data, detecting patterns and
                  anomalies. By gathering real-time information and closely monitoring global news, earnings reports,
                  and regulatory changes, Veda offers insights that can inform asset management decisions.
                  <br />
                  <br />
                  Whether you're an individual investor or an investment firm, Veda’s insights can help you optimize
                  your asset portfolio and stay ahead of market trends.
                  <br />
                  <br />
                  For example, an asset manager might leverage Veda's insights to reallocate funds in response to an
                  unexpected market event, ensuring resilience and growth.
                </p>
              </article>
              <figure className="use-cases__image">
                <img
                  width="100%"
                  src={asset_management}
                  className="asset_management-image"
                  alt="asset management image"
                />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>Onboarding</h3>
                <p>
                  Veda, the AI Pathfinder, ensures an efficient and personalized customer onboarding experience. It
                  gathers crucial financial information, including income, expenses, assets, and liabilities, and
                  utilizes this data to formulate personalized strategies.
                  <br />
                  <br />
                  Interactive questions and answers create a dynamic interaction that verifies customer identity and
                  ensures regulatory compliance, making onboarding a seamless process tailored to individual needs.
                  <br />
                  <br />
                  For instance, a small business owner could benefit from Veda's tailored onboarding, receiving
                  strategies that align with their specific business model and financial landscape.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={onboarding} className="onboarding-image" alt="onboarding image" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>Customer Service</h3>
                <p>
                  Veda, the AI Pathfinder, offers contextualized information and real-time personalized services that
                  foster customer loyalty and engagement. It is equipped to answer customer questions, handle
                  complaints, and provide insights that empower users to make informed decisions.
                  <br />
                  <br />
                  By freeing employees to focus on higher-level tasks, Veda not only improves customer satisfaction but
                  also optimizes the operational cost of branches and personnel productivity.
                  <br />
                  <br />A potential scenario is a customer calling in with a complex investment inquiry; Veda would
                  provide timely and tailored assistance, making the experience streamlined and satisfying.{' '}
                </p>
              </article>
              <figure className="use-cases__image">
                <img
                  width="100%"
                  src={customer_service}
                  className="customer_service-image"
                  alt="Customer Service image"
                />
              </figure>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
