import React from 'react';
import { motion } from 'framer-motion';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import Title from './sections/Title/Title';
import HowItWorks from './sections/HowItWorks/HowItWorks';
import UseCases from './sections/UseCases/UseCases';
import HowToUse from './sections/HowToUse/HowToUse';
import TryItNow from './sections/TryItNow/TryItNow';

import { useCheckMobile } from '../../hooks';

import './Main.scss';

function Main() {
  const isMobile = useCheckMobile({ width: 1024 });

  return (
    <div className="main" id="home">
      <Header />
      <Title />
      <HowItWorks />
      <HowToUse />
      <UseCases />
      <TryItNow />
      <section id="contact-us" className="main__contact-us-form">
        <div className="main__contact-us-form-wrapper">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? {
                    visible: { y: 0, opacity: 1 },
                  }
                : {
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: -200, opacity: 0 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1, delay: 1.2 }}
          >
            <div className="main__contact-us-form-title-col">
              <h2 className="main__contact-us-form-title">
                HAVE A<br /> CONVERSATION
                <br /> WITH VEDA The
                <br />
                <span>AI Pathfinder</span>
              </h2>
            </div>
          </motion.div>
          <RegistrationForm />
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Main;
