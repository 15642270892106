import React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

//Assets
import icon_7 from '../../../../../asset/resource/icon_7.png';
import icon_8 from '../../../../../asset/resource/icon_8.png';

import { useCheckMobile } from '../../../../hooks';

import './TryItNow.scss';

export const TryItNow = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="try-it-out" className="how-to-use try-it-out">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <span className="sub-title">TRY IT OUT</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.2 }}
        >
          <h2>
            Equality and Financial Literacy for everyone... is now a <span>reality</span>.
          </h2>
        </motion.div>
        <div className="how-to-use__items-block">
          <div className="how-to-use__item">
            <div className="how-to-use__item-icons">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.3 }}
              >
                <img src={icon_7} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <img src={icon_8} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <p>
                  Intrigued by automating financial planning tailored to individual risk profiles? Veda offers
                  revolutionary insights.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <p>
                  Interested in innovative asset management or a seamless onboarding experience? Veda delivers
                  personalized solutions.
                </p>
              </motion.div>
            </div>
          </div>
          <p className="how-to-use__items-block-bottom-text">
            Engage with Veda's real-time intelligence and uncover strategies that can elevate your organization's
            customer service, asset management, and onboarding experiences. Experience a next-level financial education
            that adapts to your needs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TryItNow;
