import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';

//Assets
import icon_1 from '../../../../../asset/resource/icon-1.png';
import icon_2 from '../../../../../asset/resource/icon-2.png';
import icon_3 from '../../../../../asset/resource/icon-3.png';
import icon_4 from '../../../../../asset/resource/icon-4.png';
import icon_5 from '../../../../../asset/resource/icon-5.png';
import icon_6 from '../../../../../asset/resource/icon-6.png';

import { useCheckMobile } from '../../../../hooks';

import './HowToUse.scss';

export const HowToUse = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isVisible, setIsVisible] = useState([]);

  const handleViewBlocks = (inView, i) => {
    if (inView) {
      setIsVisible((prevArray) => [...prevArray, (isVisible[i] = true)]);
    }
  };

  return (
    <section id="how-to-use" className="how-to-use">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <span className="sub-title">HOW TO USE </span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>
            How You Can Use Veda, the <span>AI Pathfinder</span>
          </h2>
        </motion.div>
        <div className="how-to-use__items-block">
          <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 0)} threshold={1}>
            <h3>Engage in Real Conversations with Veda </h3>
          </InView>
          <div className="how-to-use__item">
            <div className={cn('how-to-use__item-icons', isVisible[0] && 'visible')}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.3 }}
              >
                <img src={icon_1} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <img src={icon_2} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <img src={icon_3} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <p>
                  Have a meaningful dialogue with Veda, the AI Pathfinder. Share information at your comfort level, and
                  experience how Veda responds, just like a human financial educator.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <p>
                  Discuss buying a home, or exploring investment opportunities, Veda will guide you through predefined
                  scenarios, ask clarifying questions, and provide insightful answers.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.9 }}
              >
                <p>
                  Want more freedom? Engage in an open conversation, where Veda is configured to focus primarily on your
                  chosen topic, offering tailored guidance within that domain.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="how-to-use__items-block">
          <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 1)} threshold={1}>
            <h3>Explore and Learn with Veda</h3>
          </InView>
          <div className="how-to-use__item">
            <div className={cn('how-to-use__item-icons', isVisible[1] && 'visible')}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.3 }}
              >
                <img src={icon_4} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <img src={icon_5} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <img src={icon_6} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
              >
                <p>
                  Harness the financial acumen of Veda. Save for a home, or maximize returns on investments. Veda offers
                  actionable insights and visualized information.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.7 }}
              >
                <p>
                  Benefit from a gamified experience that makes learning engaging and impactful. Increase your financial
                  literacy, make informed decisions, and discover strategies that align with your unique goals.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1, delay: 0.9 }}
              >
                <p>Veda's expertise ensures a comprehensive and personalized learning journey.</p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
